import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import Image from 'next/image';
import VizSensor from 'react-visibility-sensor';
import {
  infos,
  Layout,
  routePath,
  withRedirectRoute,
} from '@typevid/ui-shared';
import clsx from 'clsx';

const Realisations: React.FC = () => {
  const list = [
    'Social Media Visuals',
    'Email Banners',
    'Display Ads',
    'Data Driven Videos',
    'Product Demos',
    'UI/UX Animations',
    'Media Kits',
    'Video Quizzes',
    'Infographics',
  ];
  const [activeIndex, setActiveIndex] = useState(-1);
  const timersRef = useRef<NodeJS.Timeout[]>([]);

  const loop = useCallback(
    (start = 0) => {
      const timeout = (i: number) => (start === 0 ? 1000 * (i + 1) : 1000 * i);
      for (let i = start, len = list.length; i < len; i += 1) {
        (function (i) {
          timersRef.current.push(
            setTimeout(function () {
              setActiveIndex(i);
            }, timeout(i))
          );
        })(i);
      }
    },
    [list.length]
  );

  const clear = useCallback(() => {
    timersRef.current?.forEach((_, i) => {
      clearTimeout(timersRef.current[i]);
    });
  }, []);

  const onVisible = useCallback(
    (visible: boolean) => {
      if (visible) {
        setActiveIndex(0);
        loop(1);
      } else {
        setActiveIndex(-1);
        clear();
      }
    },
    [clear, loop]
  );

  useEffect(() => {
    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeIndex === list.length - 1) {
      loop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  return (
    <VizSensor partialVisibility={false} onChange={onVisible}>
      <ul className="relative mt-3 h-[2.25rem] sm:h-[2.5rem] md:h-[3rem] text-3xl sm:mt-5 sm:text-4xl md:text-5xl md:mt-5 text-indigo-600 [&>li]:absolute [&>li]:left-1/2 [&>li]:-translate-x-1/2 [&>li]:leading-none [&>li]:whitespace-nowrap">
        {list.map((text, i) => (
          <li
            key={i}
            className={clsx(
              i === activeIndex
                ? 'opacity-100 top-0 duration-300'
                : 'top-full opacity-0 duration-75',
              'transition-all '
            )}
          >
            <h3>{text}</h3>
          </li>
        ))}
      </ul>
    </VizSensor>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      title: infos.siteTitle,
    },
  };
};
export const Index = (
  props: InferGetStaticPropsType<typeof getStaticProps>
) => {
  return (
    <Layout title={props.title} showFooter={true}>
      <div className="relative bg-gray-800 overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="mx-auto py-8 sm:py-16 md:py-20 lg:max-w-2xl lg:w-full lg:py-28 xl:py-32">
            <div className="max-w-7xl px-6">
              <div className="text-center">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-100 sm:text-5xl md:text-6xl">
                  <span className="block xl:inline overflow-hidden">
                    <span className="inline-block transition-transform">
                      Create
                    </span>{' '}
                    your marketing assets in{' '}
                    <span className="underline underline-offset-2 decoration-indigo-600">
                      5 minutes
                    </span>
                  </span>
                </h1>
                <p className="mt-3 text-base text-gray-200 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Typevis is a cloud-based motion design platform that uses
                  plug-and-play technology to help you automate and scale your
                  marketing quickly. No coding required.
                </p>
                <div className="mt-5 sm:mt-8 flex justify-center">
                  <div className="rounded-md shadow">
                    <a
                      href={routePath.signup}
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 "
                    >
                      Join the Beta
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-6 pb-8 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
            <div className="relative w-full">
              <Image
                className="w-full object-cover"
                src="/assets/lp/01-typevis-web-motion-deigner.jpg"
                alt="typevis web-based motion deigner"
                layout="responsive"
                width="1920"
                height="1080"
                priority={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 lg:px-8 py-8 sm:py-16 md:py-20 lg:max-w-2xl lg:w-full lg:py-28 xl:py-32">
        <div className="text-center">
          <h2 className="text-4xl text-gray-900 tracking-tight font-extrabold sm:text-5xl md:text-6xl">
            Typevis Powers
          </h2>
          <Realisations />
          <p className="mt-3 text-base sm:mt-5 sm:text-lg md:mt-5 md:text-xl">
            (and anything else you can think of)
          </p>
        </div>
      </div>

      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8 py-8 sm:py-16 md:py-20 lg:py-28 xl:py-32">
          <div className="space-y-8 sm:space-y-16 md:space-y-20 lg:space-y-28 xl:space-y-32">
            <div className="flex flex-col lg:flex-row space-x-0 space-y-8 sm:space-y-16 md:space-y-20 lg:space-y-0 lg:space-x-28 xl:space-x-32">
              <div className="flex-1">
                <h2 className="text-3xl text-gray-900 tracking-tight font-extrabold sm:text-4xl md:text-5xl">
                  Design Once
                </h2>
                <p className="mt-3 text-base sm:mt-5 sm:text-lg md:mt-5 md:text-xl">
                  Use our Visual Designer to create your template for one format
                  then adapt it to other formats. Add animations to the Dynamic
                  Timeline for a fast and scalable design powered by HTML, CSS,
                  and Javascript.
                </p>
              </div>
              <div className="flex-1">
                <div className="relative w-full">
                  <Image
                    className="w-full object-cover"
                    src="/assets/lp/02-web-based-motion-designer.jpg"
                    alt="web-based motion deigner"
                    layout="responsive"
                    width="960"
                    height="540"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row space-x-0 space-y-8 sm:space-y-16 md:space-y-20 lg:space-y-0 lg:space-x-28 xl:space-x-32">
              <div className="flex-1 lg:order-1 ">
                <h2 className="text-3xl text-gray-900 tracking-tight font-extrabold sm:text-4xl md:text-5xl">
                  Export Everywhere
                </h2>
                <p className="mt-3 text-base sm:mt-5 sm:text-lg md:mt-5 md:text-xl">
                  Use our simple On-site Editor to add content and images to
                  your media. Export your creation to all the formats and file
                  types you want. Typevis saves you time and resources by
                  rendering your exports on the Cloud instead of on your device
                  or browser.
                </p>
              </div>
              <div className="flex-1 lg:!ml-0 lg:!mr-28 xl:!mr-32">
                <div className="relative w-full">
                  <Image
                    className="w-full object-cover"
                    src="/assets/lp/03-web-based-motion-editor.jpg"
                    alt="web-based motiondesign editor"
                    layout="responsive"
                    width="960"
                    height="540"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row space-x-0 space-y-8 sm:space-y-16 md:space-y-20 lg:space-y-0 lg:space-x-28 xl:space-x-32">
              <div className="flex-1">
                <h2 className="text-3xl text-gray-900 tracking-tight font-extrabold sm:text-4xl md:text-5xl">
                  Automate The Repetitiveness
                </h2>
                <p className="mt-3 text-base sm:mt-5 sm:text-lg md:mt-5 md:text-xl">
                  Create automated workflows to streamline your repetitive
                  tasks, reduce errors, and execute faster. Focus on growing
                  your business by using our robust integration solutions.
                </p>
              </div>
              <div className="flex-1">
                <div className="relative w-full">
                  <Image
                    className="w-full object-cover"
                    src="/assets/lp/04-motion-design-automation.jpg"
                    alt="web-based motion design automation"
                    layout="responsive"
                    width="960"
                    height="540"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row space-x-0 space-y-8 sm:space-y-16 md:space-y-20 lg:space-y-0 lg:space-x-28 xl:space-x-32">
              <div className="flex-1 lg:order-1">
                <h2 className="text-3xl text-gray-900 tracking-tight font-extrabold sm:text-4xl md:text-5xl">
                  Scale Your Marketing
                </h2>
                <p className="mt-3 text-base sm:mt-5 sm:text-lg md:mt-5 md:text-xl">
                  Increase efficiency and provide personalized experiences to
                  your customers by using our REST API and your dynamic
                  template.
                </p>
              </div>
              <div className="flex-1 lg:!ml-0 lg:!mr-28 xl:!mr-32">
                <div className="relative w-full">
                  <Image
                    className="w-full object-cover"
                    src="/assets/lp/05-motion-design-scale.jpg"
                    alt="web-based motion design scale"
                    layout="responsive"
                    width="960"
                    height="540"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row space-x-0 space-y-8 sm:space-y-16 md:space-y-20 lg:space-y-0 lg:space-x-28 xl:space-x-32">
              <div className="flex-1">
                <h2 className="text-3xl text-gray-900 tracking-tight font-extrabold sm:text-4xl md:text-5xl">
                  Manage Your Work
                </h2>
                <p className="mt-3 text-base sm:mt-5 sm:text-lg md:mt-5 md:text-xl">
                  Typevis lets you grant different levels of access, so your
                  team can work together. Preload templates with your brand
                  assets and guidelines so everyone stays on the same page.
                </p>
              </div>
              <div className="flex-1">
                <div className="relative w-full">
                  <Image
                    className="w-full object-cover"
                    src="/assets/lp/06-motion-design-manage.jpg"
                    alt="web-based motion design manger"
                    layout="responsive"
                    width="960"
                    height="540"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 lg:px-8 py-8 sm:py-16 md:py-20 lg:max-w-2xl lg:w-full lg:py-28 xl:py-32">
        <div className="text-center">
          <h2 className="text-3xl text-gray-900 tracking-tight font-extrabold sm:text-4xl md:text-5xl">
            Ready to use Typevis?
          </h2>

          <div className="mt-5 sm:mt-8 flex justify-center">
            <div className="rounded-md shadow">
              <a
                href={routePath.signup}
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 "
              >
                Join the Beta
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withRedirectRoute(Index);
